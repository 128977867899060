
import { defineNuxtPlugin } from '#app';
import { useSiteDataStore } from '~/store/siteDataStore';
// import SiteTranslationsQuery from '~/graphql/queries/global/SiteTranslations.graphql';
import SiteGlobals from '~/graphql/queries/global/SiteGlobals.graphql';


export default defineNuxtPlugin(async() => {
    const {variables} = useMultisite();
    const siteDataStore = useSiteDataStore();

    if (toValue(siteDataStore.isFetched)) {
        console.log('siteData already fetched');
        return;
    }

    const {data} = await useAsyncQuery({
        query: SiteGlobals,
        variables: {
            site: variables.site,
            language: ['en-US', 'nl-NL', 'ar'],
            category: ['context-nav', 'types', 'site', 'components'],
        },
    });

    siteDataStore.setSiteData(data);

});
