import validate from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_less@4.2.0_stylelint@14.16.1_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirects_45global from "/builds/uroweb/uroweb-frontend/layers/redirects/middleware/redirects.global.ts";
import manifest_45route_45rule from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.11_eslint@8.57.1_less@4.2.0_stylelint@14.16.1_typescript@5.6.3_vite@5.4.8_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_next-auth@4.21.1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}